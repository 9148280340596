import React from 'react'
import TitleHeader from './TitleHeader'
import DonutChart from './Chart/DonutChart'

const AirDropResult = () => {
    return (
        <div className='my-32'>
            <TitleHeader title="AirDrop Results" />
            <div className='Pfixed flex gap-8 mt-14 max-[930px]:flex-wrap'>

                <div className='w-1/2 h-full flex flex-col gap-10 max-[930px]:w-full'>
                    <div className='flex flex-col gap-4'>
                        <label htmlFor="" className=''>Address</label>
                        <div className='flex items-center outline-gradient p-[1px] rounded-md'>
                            <textarea name="" id="" className='w-full p-5 pb-6 rounded-md' placeholder='Your Address Here'></textarea>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <label htmlFor="" className=''>Traits Percentage</label>
                        <div className='flex flex-col gap-10'>
                            <div className='flex items-center outline-gradient p-[1px] rounded-md'>
                                <div className='w-full flex items-center justify-between secondaryBG p-2 rounded-md'>
                                    <div className='w-[70%] flex justify-center items-center px-2 py-4 text-white text-4xl rounded-md max-[600px]:text-xl' style={{ backgroundImage: 'linear-gradient(to right, #19612B 0%, #34C759 100%)' }}>1700 USDC</div>
                                    <div className='w-[30%] flex justify-end text-4xl pr-4 max-[600px]:text-xl'>70%</div>
                                </div>
                            </div>
                            <div className='flex items-center outline-gradient p-[1px] rounded-md'>
                                <div className='w-full flex items-center justify-between secondaryBG p-2 rounded-md'>
                                    <div className='w-[70%] flex justify-center items-center px-2 py-4 text-white text-4xl rounded-md max-[600px]:text-xl' style={{ backgroundImage: 'linear-gradient(to right, #07587E 0%, #32ADE6 100%)' }}>5000 USDC</div>
                                    <div className='w-[30%] flex justify-end text-4xl pr-4 max-[600px]:text-xl'>30%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-1/2 h-full flex flex-col gap-4 max-[930px]:w-full'>
                    <label htmlFor="" className=''>Calculation of the total parts</label>
                    <div className='flex items-center outline-gradient p-[1px] rounded-md'>
                        <div className='w-full flex items-center justify-center secondaryBG h-[410px] p-2 rounded-md max-[600px]:p-2 max-[480px]:h-[auto]'>
                            <DonutChart />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AirDropResult