import { Route, Routes } from 'react-router-dom'
import Routing from './Routing';
import Hero from './Components/Home/Hero';
import NftAirDrop from './Pages/Nft Airdrop/NftAirDrop';

function App() {
  return (
    <Routes>

      <Route exact path='/' element={<Routing />}>
        <Route index path='/' element={<NftAirDrop />} />
        {/* <Route index path='/' element={<Hero />} /> */}
      </Route>

    </Routes>
  );
}

export default App;
