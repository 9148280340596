import React from 'react'

const TitleHeader = ({title}) => {
    return (
        <div className='Pfixed'>
            <h2 className='h2 text-center mb-2'>{title}</h2>
            <img src="../../assets/Images/Line 7.svg" className='w-full'  alt="" />
        </div>
    )
}

export default TitleHeader