import React from 'react'
import { Outlet } from 'react-router-dom'
import MaxWidthWrapper from './utils/MaxWidthWrapper'
import NavBar from './Components/nav/NavBar'

const Routing = () => {
    return (
        <div>
            {/* // <MaxWidthWrapper> */}
            {/* <NavBar/> */}
            <Outlet />
            {/* // </MaxWidthWrapper> */}
        </div>
    )
}

export default Routing