import { createContext, useContext } from "react";
import * as web3 from "@solana/web3.js"
import { AnchorProvider } from "@project-serum/anchor";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
const WalletContext = createContext(undefined)

export const WalletApi = ({ children }) => {
    const wallet = useAnchorWallet()
    const connection = new web3.Connection(
        web3.clusterApiUrl("devnet", true),
        "confirmed"
    )

    const provider = new AnchorProvider(
        connection,
        wallet,
        "confirmed"
    )




    return (
        <WalletContext.Provider value={{
            connection,
            provider
        }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWalletContext = () => useContext(WalletContext);


export default WalletContext;

// import { useAnchorWallet, useWallet } from '@solana/wallet-adapter-react';
// import { createContext, useState, useContext, useEffect } from 'react';
// import * as web3 from "@solana/web3.js";
// import { AnchorProvider } from "@project-serum/anchor";
// import * as anchor from "@project-serum/anchor";
// import { keys } from '../Web3/config'
// import idl from "../Web3/idl.json";
// import axios from 'axios';
// import { fetchDataFromAccount } from '../Web3/programFunctions';




// const WalletContext = createContext();

// export const WalletApi = ({ children }) => {
//     const [accountData, setAccountData] = useState();
//     const [refreshData,setRefreshData] = useState(false);
//     const [activePhase,setActivePhase]=useState(undefined)
//     const wallet = useAnchorWallet();


//     const connection = new web3.Connection(
//         web3.clusterApiUrl("devnet", true),
//         "confirmed"
//     );

//     const provider = new AnchorProvider(
//         connection,
//         wallet,
//         "confirmed"
//     );


//     const program = new anchor.Program(
//         idl,
//         keys.programId,
//         provider
//     );

//     useEffect(() => {
//         fetchDataFromAccount().then((data) => {
//             setAccountData(data)
//         })
//     }, [refreshData])

//     const findActivePhase=(accountData,setActivePhase)=>{
//         const currentTime = Math.floor(Date.now() / 1000); // Get current Unix timestamp

//         if (currentTime < accountData?.phaseOneTime) {
//             setActivePhase(1)
//           return 1; // Phase one is active
//         } else if (currentTime < accountData?.phaseTwoTime) {
//             setActivePhase(2)
//           return 2; // Phase two is active
//         } else {
//             setActivePhase(-1)
//           return -1; // All phases have ended
//         }
//     }
//     useEffect(()=>{
//         if(accountData) findActivePhase(accountData,setActivePhase)
//     },[accountData])

// console.log("active-phase",activePhase)

//     return (
//         <WalletContext.Provider value={{
//             provider,
//             program,
//             connection,
//             activePhase,
//             accountData,
//             refreshData,
//             setRefreshData
//         }}>
//             {children}
//         </WalletContext.Provider>
//     );
// };


// export const useWalletContext = () => useContext(WalletContext);


// export default WalletContext;

