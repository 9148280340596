import React, { useState } from 'react';
import TitleHeader from './TitleHeader';
import DistributeBox from './DistributeBox';
import Modal from './Modal/Modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import axios from 'axios';
// import { transfer } from '../Components/Transction';
import { transfer, newFuncion } from '../Web3/web3Functions';
import { useWalletContext } from '../context/walletContext';
import { useWallet, useAnchorWallet } from '@solana/wallet-adapter-react';


const Distribution = ({ ownerAddress, transferIT }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [transaction, setTransaction] = useState();
    const { connection } = useWalletContext();
    const { publicKey, sendTransaction } = useWallet();
    const wallet = useAnchorWallet()
    const [Loading, setLoading] = useState();
    // const [ownerAddresses, setOwnerAddresses] = useState([]);
    // const [connection, setConnection] = useState();

    console.log('check transfer', transferIT.amount)
    console.log('publicKey', publicKey)
    const handleOpenModal = async () => {
        if (publicKey) {
            setLoading(true)
            //  const transferResult = await transfer(ownerAddress, transferIT.amount, publicKey, wallet, connection, setLoading);
            const transferResult = await newFuncion(ownerAddress, publicKey, transferIT.amount, wallet);
            console.log("Return value:", transferResult);
            setTransaction(transferResult);
            setIsModalVisible(true);
            toast.success("Successfully Transfer", { autoClose: 500 });
            setLoading(false)
            // try {
            //     const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/transfer`, transferIT, {
            //         headers: {
            //             'Content-Type': 'application/json'
            //         }
            //     });

            //     console.log("Check Datasssss", response);

            //     if (response.status === 200) {
            //         const transferResult = await transfer(ownerAddress, transferIT.amount);
            //         console.log("Return value:", transferResult);
            //         setTransaction(transferResult);
            //         setIsModalVisible(true);
            //         toast.success("Distribution calculated successfully");
            //     } else {
            //         toast.error("Failed to calculate distribution");
            //     }
            // } catch (error) {
            //     toast.error("Error occurred while calculating distribution");
            // }
        } else {
            toast.error("Wallet is not Connected")
        }
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    console.log("transferIT", transferIT);
    return (
        <div className='my-[100px]'>
            <TitleHeader title="Distribution USDC" />
            <div className='Pfixed mt-8'>
                <label htmlFor="">Distribute Details</label>
                <div className='mt-4'>
                    {ownerAddress.map((address, index) => (
                        <DistributeBox key={index} title={address.ownerAddress} usd="66.67 USDC" />
                    ))}
                </div>

                <button
                    className={`newBtn w-full p-[22px] text-[#8E8E93] text-4xl secondaryBG rounded-md mt-14 border-gray max-[600px]:text-xl ${Loading ? 'animate-pulse' : ''}`}
                    onClick={handleOpenModal}
                    disabled={Loading}
                >
                    Send USDC
                </button>
            </div>
            {transaction && (
                < Modal isVisible={isModalVisible} onClose={handleCloseModal} transaction={transaction} />
            )}
            <ToastContainer />
        </div>
    );
};

export default Distribution;
