import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='Pfixed' >
            <img src="../../assets/Images/Line 4.svg" alt="" />

            <div className='flex flex-col items-center gap-3 my-10'>
                <Link to="/"><img src="../../logo.svg" alt="" className='w-[40px]' /></Link>
                <Link to="/"><h5 className="text-white font-bold text-xl">Hulk Cars</h5></Link>
                <p className='text-[#8E8E93]'>Join our community  for the latest updates and exclusive offers.</p>
                <div className='flex item-center gap-3 mt-2'>
                    <Link to="" className='bg-[#1E2122] rounded-md p-1'><img src="../../assets/Icons/X.png" className='w-[30px]' alt="" /></Link>
                    <Link to="" className='bg-[#1E2122] rounded-md p-1'><img src="../../assets/Icons/telegram.png" className='w-[30px]' alt="" /></Link>
                    <Link to="" className='bg-[#1E2122] rounded-md p-1'><img src="../../assets/Icons/discord.png" className='w-[30px]' alt="" /></Link>
                    <Link to="" className='bg-[#1E2122] rounded-md p-1'><img src="../../assets/Icons/youtube.png" className='w-[30px]' alt="" /></Link>
                </div>
            </div>
        </div>
    )
}

export default Footer