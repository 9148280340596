import React, { useState, useEffect } from 'react'
import TitleHeader from './TitleHeader'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const SelectSetting = ({ setOwnerAddress, settransferIT }) => {
    const [dropDownShow, setDropDownShow] = useState(false)
    const [selectedCarType, setSelectedCarType] = useState("Choose a Car Type")
    const [hoveredCarType, setHoveredCarType] = useState(null)
    const [amount, setAmount] = useState("")
    const [carTypes, setCarTypes] = useState([])
    const [loading, setLoading] = useState(false) // Added loading state

    useEffect(() => {
        const fetchCarTypes = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_SERVER_URL + 'colors')
                console.log('Check kr yaar', response)
                if (!response.ok) {
                    throw new Error("Failed to fetch car types", { autoClose: 500 })
                }
                const data = await response.json()
                setCarTypes(data.colors)
            } catch (error) {
                toast.error("Failed to fetch car types", { autoClose: 500 })
            }
        }
        fetchCarTypes()
    }, [])

    const handleDropdown = () => {
        setDropDownShow(!dropDownShow)
    }

    const handleCarTypeSelect = (carType) => {
        setSelectedCarType(carType)
        setDropDownShow(false)
    }

    const handleMouseEnter = (carType) => {
        setHoveredCarType(carType)
    }

    const handleMouseLeave = () => {
        setHoveredCarType(null)
    }

    const handleInputChange = (e) => {
        setAmount(e.target.value)
    }

    const handleCalculateDistribution = async () => {
        if (selectedCarType === "Choose a Car Type") {
            toast.error("Please select a car type", { autoClose: 500 })
            return
        }
        if (!amount) {
            toast.error("Please enter the amount of USDC", { autoClose: 500 })
            return
        }
        setLoading(true)
        const data = {
            color: selectedCarType,
            amount: parseFloat(amount)
        }
        settransferIT(prevState => ({
            ...prevState,
            color: selectedCarType,
            amount: parseFloat(amount)
        }));

        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + `owner-addresses`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })

            if (!response.ok) {
                throw new Error("Failed to calculate distribution", { autoClose: 500 })
            }

            const responseData = await response.json()
            console.log("Check Data", responseData)
            setOwnerAddress(responseData)
            alert("Distribution calculated successfully")
            // toast.success("Distribution calculated successfully", { autoClose: 500 }) 
        } catch (error) {
            toast.error("Error occurred while calculating distribution", { autoClose: 500 })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <TitleHeader title="Select Your Settings" />
            <div className='Pfixed'>
                <div className='py-8'>
                    <label htmlFor="">Select your Car</label>
                    <div className='relative'>
                        <div className='outline-gradient p-[1px] rounded-md mt-7'>
                            <div className='flex justify-between items-center py-4 px-5 cursor-pointer bg-[#1E2122] rounded-md' onClick={handleDropdown}>
                                <div className='text-[#8E8E93] text-xl'>{selectedCarType}</div>
                                <img src="../../assets/Icons/Group 67.png" alt="" />
                            </div>
                        </div>
                        {dropDownShow && (
                            <div className='w-full px-14 py-5 rounded-md absolute bg-[#121212] mt-2 max-[600px]:p-2'>
                                {carTypes.map((carType) => (
                                    <div
                                        key={carType}
                                        className='flex justify-between items-center px-14 py-3 hover:bg-[#7F7F7F33] rounded-md cursor-pointer max-[600px]:p-2'
                                        onClick={() => handleCarTypeSelect(carType)}
                                        onMouseEnter={() => handleMouseEnter(carType)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <span className='text-white text-xl'>{carType}</span>
                                        <div className='border-primary w-[40px] h-[40px] rounded-full p-1'>
                                            {hoveredCarType === carType && (
                                                <div className='bg-[#D9D9D9] w-full h-full rounded-full'></div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className='flex flex-col'>
                    <label htmlFor="">USDC to Distribute</label>
                    <div className='outline-gradient mt-7 p-[1px] rounded-md'>
                        <input
                            className='w-full p-5 pb-6 rounded-md'
                            type="text"
                            name=""
                            id=""
                            placeholder='Amount of USDC'
                            value={amount}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <button
                    className={`newBtn w-full p-[22px] text-[#8E8E93] text-4xl secondaryBG rounded-md mt-14 border-gray max-[600px]:text-xl ${loading ? 'animate-pulse' : ''}`}
                    onClick={handleCalculateDistribution}
                    disabled={loading}
                >
                    Calculate Distribution
                </button>
            </div>
            <ToastContainer />
        </div>
    )
}

export default SelectSetting
