// const anchor = require('@project-serum/anchor');

export const keys = {
    token: "25CWMRXL54vjdcB6eDxmvmUooApd7naw4wHiZmfMpvD2",
    associated_account: '7vSRhLYKaqvrq1bvP5p3fb3XFw1nv9KQu9g5K31cyc3q'
};




