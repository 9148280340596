import React from 'react'

const DistributeBox = ({ title, usd }) => {
    return (
        <div className="flex justify-between py-4 border-bottom">
            <h6 className='text-white text-xl'>{title?.slice(0, 4)}<span>... </span>{title?.slice(-4,-1)}</h6>
            <h6 className='text-white text-xl'>{usd}</h6>
        </div>
    )
}

export default DistributeBox