import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const DonutChart = () => {
    const [options, setOptions] = useState({
        chart: {
            type: 'donut',
        },
        labels: ['Category A', 'Category B', 'Category C', 'Category D'],
        dataLabels: {
            style: {
                colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']
            }
        },
        legend: {
            labels: {
                colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']
            }
        },
        plotOptions: {
            pie: {
                expandOnClick: true,
                donut: {
                    size: '50%',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            color: '#FFFFFF'
                        },
                        value: {
                            show: true,
                            color: '#FFFFFF'
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            color: '#FFFFFF',
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                            }
                        }
                    }
                }
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 350,
                },
                legend: {
                    position: 'bottom',
                    labels: {
                        colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']
                    }
                }
            }
        }]
    });

    const [series, setSeries] = useState([44, 55, 41, 17]);

    return (
        <div className="w-full h-full flex items-center justify-center">
            <Chart
                options={options}
                series={series}
                type="donut"
                width="480px"          
            />
        </div>
    );
};

export default DonutChart;
