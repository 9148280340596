// Modal.js
import React, { useEffect } from 'react';

const Modal = ({ isVisible, onClose, transaction }) => {
    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isVisible]);

    if (!isVisible) return null;

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-80 p-5 flex justify-center overflow-y-auto max-h-[100vh]"
            onClick={handleOutsideClick}
        >
            <div className='outline-gradient h-auto w-[780px] p-[1px] rounded-md '>
                <div className="flex flex-col justify-center items-center bg-[#121212] h-full p-14 rounded-md max-[600px]:p-6 max-[600px]:px-4">
                    <img src="../../../assets/Images/Group 64.png" className='w-[70px] mx-auto' alt="Payment Successful" />
                    {/* <h2 className="text-2xl py-5">Payment Successful</h2> */}
                    {/* <a href={`https://explorer.solana.com/tx/${transaction}?cluster=devnet`} target='_blank' className="underline text-2xl py-5 flex items-center text-center">{transaction}</a> */}
                    <a href={`https://explorer.solana.com/tx/${transaction}?cluster=devnet`} target='_blank' className="underline text-2xl py-5 flex items-center text-center">{transaction?.slice(0, 10)}<span>... </span>{transaction?.slice(-10,-1)}</a>

                    {/* <div className='flex flex-col gap-3'>
                        <div className='flex justify-between items-center'>
                            <span className='text-white text-md max-[600px]:text-xs'>NFT Name</span>
                            <span className='text-white text-md max-[600px]:text-xs'>Porsche 911 Targa 4</span>
                        </div>
                        <div className='flex justify-between items-center'>
                            <span className='text-white text-md max-[600px]:text-xs'>NFT ID</span>
                            <span className='text-white text-md max-[600px]:text-xs'>747</span>
                        </div>
                        <div className='flex justify-between items-center'>
                            <span className='text-white text-md max-[600px]:text-xs'>Payment Type</span>
                            <span className='text-white text-md max-[600px]:text-xs'>Stablecoin (USDC)</span>
                        </div>
                        <div className='flex justify-between items-center'>
                            <span className='text-white text-md max-[600px]:text-xs'>Total Number of NFTs</span>
                            <span className='text-white text-md max-[600px]:text-xs'>2% NFT</span>
                        </div>
                        <div className='flex justify-between items-center'>
                            <span className='text-white text-md'>Total USDC</span>
                            <span className='text-white text-md'>66.67 USDC</span>
                        </div>
                        <div className='flex justify-between items-center'>
                            <span className='text-white text-md'>Transaction Number</span>
                            <span className='text-white text-md'>14</span>
                        </div>
                    </div> */}

                    <button className='w-full p-[14px] text-[#34C759] text-2xl secondaryBG rounded-md mt-8 border-gray max-[600px]:text-xl max-[600px]:p-2 max-[600px]:text-white max-[600px]:bg-[#34C759]' onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
