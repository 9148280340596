import React from 'react'

const Hero = () => {
    return (
        <div className='Pfixed flex justify-center items-center' style={{ padding: '150px 0px' }}>
            <h1 className='text-white text-5xl font-bold'>NFT Airdrop</h1>
        </div>
    )
}

export default Hero