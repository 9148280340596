import React from 'react';
import { Link } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react'
import {  WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import "../Components/Navbar"

const Navbar = () => {
    const { publicKey ,connected} = useWallet()
    console.log("connected wallet info", publicKey ,connected)

    return (
        <div className='navbar Pfixed flex justify-between items-center fixed h-[70px] bg-[#071203]'>
            <Link to="/" className='flex items-center gap-3'>
                <img src="../../logo.svg" className='w-[40px] h-[40px]' alt="" />
                <h5 className='text-white text-xl font-bold'>Hulk Cars</h5>
            </Link>
            <WalletMultiButton />
        </div>
    );
}

export default Navbar;