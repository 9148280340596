import React, { useState } from 'react'
import Navbar from '../../Components/Navbar'
import Hero from '../../Components/Hero'
import SelectSetting from '../../Components/SelectSetting'
import AirDropResult from '../../Components/AirDropResult'
import Distribution from '../../Components/Distribution'
import Footer from '../../Components/Footer'

const NftAirDrop = () => {
    const [ownerAddress, setOwnerAddress] = useState([])
    const [transferIT, settransferIT] = useState({
        color: 'Hello, World!',
        amount: 0
    })

    return (
        <div className='relative'>
            {/* <div class="ellipse-gradient"></div> */}
            <Navbar />
            <Hero />
            <SelectSetting setOwnerAddress={setOwnerAddress} settransferIT={settransferIT} />
            <AirDropResult />
            <Distribution ownerAddress={ownerAddress} transferIT={transferIT} />
            <Footer />
        </div>
    )
}

export default NftAirDrop