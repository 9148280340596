import * as buffer from 'buffer';
import * as web3 from '@solana/web3.js';
import * as anchor from '@project-serum/anchor';
import { getOrCreateAssociatedTokenAccount, createTransferInstruction } from '@solana/spl-token';
import { toast } from 'react-toastify';

import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
const { keys } = require('../config');

window.Buffer = buffer.Buffer;

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new web3.PublicKey(
    'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL',
);

// export async function transfer(
//     accounts,
//     totalAmount,
//     publicKey,
//     wallet,
//     connection,
//     setLoading
// ) {
//     try {
//         if (!totalAmount) {
//             setLoading(false);
//             return toast.error('Invalid amount');
//         }

//         if (!connection) {
//             setLoading(false);
//             return toast.error('Connection not initialized');
//         }

//         const mint = new web3.PublicKey(keys.token);
//         console.log("accounts", accounts)

//         const latestBlockhash = await connection.getLatestBlockhash('finalized');
//         console.log('Starting transfer function with accounts:', accounts, 'and totalAmount:', totalAmount);

//         if (!Array.isArray(accounts) || accounts.length === 0) {
//             throw new Error('Accounts array is empty or not an array');
//         }

//         let associatedAccount = findAssociatedTokenAddress(publicKey, mint)
//         console.log("user ata", associatedAccount?.toBase58())

//         const amount = Math.floor(totalAmount / accounts.length);
//         const lamports_value = 1000000;
//         const amountPerAccount = amount * lamports_value;
//         console.log('Calculated amount per account:', amountPerAccount);

//         const transaction = new web3.Transaction();

//         for (const account of accounts) {
//             console.log('Processing receiver public key:', account.ownerAddress);
//             const receiverPublicKey = new web3.PublicKey(account.ownerAddress);
//             console.log('Created receiver public key object:', receiverPublicKey);

//             const associatedTokenAccountReceiver = await getOrCreateAssociatedTokenAccount(
//                 connection,
//                 publicKey,
//                 mint,
//                 receiverPublicKey
//             );
//             console.log('Retrieved associated token account receiver address:', associatedTokenAccountReceiver.address);

//             const transferInstruction = createTransferInstruction(
//                 associatedAccount, // From this associated account
//                 associatedTokenAccountReceiver.address, // To this receiver
//                 publicKey, // From this signer
//                 amountPerAccount // Amount to transfer
//             );
//             transaction.add(transferInstruction);
//         }

//         transaction.feePayer = publicKey;
//         transaction.recentBlockhash = latestBlockhash.blockhash;

//         const signedTx = await wallet.signTransaction(transaction);
//         console.log('Signed transaction:', signedTx);

//         const signature = await connection.sendRawTransaction(signedTx.serialize());
//         console.log('Transaction signature:', signature);

//         const isConfirmed = await connection.confirmTransaction(signature, 'finalized');
//         console.log('Transaction confirmation:', isConfirmed);

//         if (isConfirmed.value.err) {
//             throw new Error('Transaction failed');
//         }

//         return signature;
//     } catch (error) {
//         console.error('Failed to transfer with error:', error);
//         setLoading(false);
//         toast.error('Transfer failed: ' + error.message);
//         return { error: error.message };
//     }
// }


export const newFuncion = async (accounts, publicKey, totalAmount,wallet) => {
    try {
        const connection = new web3.Connection(
            web3.clusterApiUrl("devnet", true),
            "confirmed"
        )

        const mint = new web3.PublicKey('6qbKitgsQ36KqdhU4sEb1P1afQbVLG4MeiryuY9nM5bH'); // Replace with your mint address
        //const associatedAccount = new web3.PublicKey('4Crd7wikKJktLEDfLTFw3M4WVLDtMq6MgkUFQdqQyFxW'); // Replace with your associated account address
       let associatedAccount = findAssociatedTokenAddress(publicKey, mint)

        if (!publicKey) {
            console.error('No wallet connected');
            return;
        }

        if (!Array.isArray(accounts) || accounts.length === 0) {
            console.error('Accounts array is empty or not an array');
            return;
        }

        try {
            const latestBlockhash = await connection.getLatestBlockhash('finalized');

            const amount = Math.floor(totalAmount / accounts.length);
            const laports_value = 100000000;
            const amountPerAccount = amount * laports_value;
            console.log('Calculated amount per account:', amountPerAccount);

            const transaction = new web3.Transaction();
            for (const account of accounts) {
                const receiverPublicKey = new web3.PublicKey(account.ownerAddress);
                const associatedTokenAccountReceiver = await getOrCreateAssociatedTokenAccount(
                    connection,
                    publicKey, // Use publicKey as the payer
                    mint,
                    receiverPublicKey,
                );
                console.log("atac",associatedTokenAccountReceiver,associatedTokenAccountReceiver.address)

                const transferInstruction = createTransferInstruction(
                    associatedAccount, // From this associated account
                    associatedTokenAccountReceiver.address, // To this receiver
                    publicKey, // From this signer
                    amountPerAccount // Amount to transfer
                );

                transaction.add(transferInstruction);
            }

           

            transaction.feePayer = publicKey;
            transaction.recentBlockhash = latestBlockhash.blockhash;
    
            const signedTx = await wallet.signTransaction(transaction);
            console.log('Signed transaction:', signedTx);
    
            const signature = await connection.sendRawTransaction(signedTx.serialize());
            console.log('Transaction signature:', signature);
    
            const isConfirmed = await connection.confirmTransaction(signature, 'finalized');
            console.log('Transaction confirmation:', isConfirmed);
 return signature
        } catch (error) {
            console.error('Failed to transfer with error:', error);
        }

    } catch (error) {

    }
}


export function findAssociatedTokenAddress(walletAddress, tokenMintAddress) {
    return web3.PublicKey.findProgramAddressSync(
        [
            walletAddress.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            tokenMintAddress.toBuffer(),
        ],
        SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    )[0];
}

